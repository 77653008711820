import "../styles/new.css"
import styles from '../styles/Home.module.css'
import Header from './Header'
import App from "./App"
import Nft from "./Nft"
import { Routes, Route } from "react-router-dom";
import React from "react"




function Container() {

    return (
       
        <div className="Container"> 

          <Header/>
          <div className={styles.container}>
          <body> 

            <Routes>
            <Route path="/" element={<App/>} />
              <Route path="/nft" element={<Nft/>} />
            </Routes>

            </body>

            </div>

          </div>
    )

    
}



export default Container;