import React from 'react'
import styles from '../styles/Home.module.css'
import Anaheim from "../logo/anaheim.jpg"
import Jalapeno from "../logo/jalapeno.jpg"
import Serrano from "../logo/serrano.jpg"
import Cayenne from "../logo/cayenne.jpg"
import Pequin from "../logo/pequin.jpg"
import Malagueta from "../logo/malagueta.jpg"
import Habanero from "../logo/habanero.jpg"
import Anniversary from "../logo/anniversary.jpg"
import Footer from './Footer'



function Nft() {
  return (


    <div className={styles.container}>
      

      <main className={styles.main}>

      <div className='nft_section'>

    <div className='title_section'>
      <h1>CHILIZ NFTs MARKETPLACE 🌶️ </h1>
      <h2>Coming soon... </h2>
      <text> Get a chance to win a Chiliz NFT </text>  
      <p>👇👇👇 </p>
      <p>  <a href='https://docs.google.com/forms/d/1XekFCtf_YaMMkoKJha-vSzZkDXnykJlqdiCVxJ1rBSA' target='_blank'> FILL IN THIS FORM </a></p>

      </div>

    <div className="row">
  <div className="column">
  <img src={Anaheim} />
  <img src={Jalapeno} />  
  </div>  
  <div className="column">
  <img src={Serrano} />
  <img src={Cayenne} />
  </div>
  <div className="column">
  <img src={Pequin} />
  <img src={Malagueta} />
  </div>
  <div className="column">
  <img src={Habanero} />
  <img src={Anniversary} />

  </div>
  </div>
  
  </div>
    
  <Footer></Footer>

    </main>

    </div>

  )
}

export default Nft