import React from 'react'
import { useCallback, useRef, useState } from 'react'
import { useActiveProvider } from '../connectors'
import Logo from '../logo/uniswap.png'
import Logoeth from '../logo/eth_logo.png'
import '../styles/new.css'
import Nft from './Nft'
import Web3Connectors from './Web3Connectors'
import styles from '../styles/Home.module.css'
import '../styles/hamburgermenu.css'
import { Link } from 'react-router-dom'

function Header() {
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])

  // The provider to pass to the SwapWidget.
  // This is a Web3Provider (from @ethersproject) supplied by @web3-react; see ./connectors.ts.
  const provider = useActiveProvider()

  return (
    <header>
      <div className="leftH">
        <Link to="/" className="link">
          <img src={Logo} alt="logo" className="logo" />
        </Link>
        <div className="headerTitle">
          <Link to="/" className="link">
            <h1 className={styles.title}>
              Chiliz<span>Swap</span>
            </h1>
          </Link>
        </div>
      </div>

      <div className="headerItem">
        <Link to="https://nft.chilizswap.com/" className="link" target="_blank">
          <div className="headerItem_sub">NFTs Marketplace</div>
        </Link>

        <Link to="https://www.fanmarketcap.com/" className="link" target="_blank">
          <div className="headerItem_sub">Fan Tokens</div>
        </Link>

        <Link to="https://bridge.chiliz.com/transfer" target="_blank" className="link">
          <div className="headerItem_sub">Bridge</div>
        </Link>
      </div>

      <div className="rightH">
        <div className="headerItem">
          <div className="eth_network">
            <img src={Logoeth} alt="eth" className="eth" />
            <div className="ethtext">Ethereum</div>
          </div>
        </div>

        <div className={styles.connectors} ref={connectors} tabIndex={-1}>
          <Web3Connectors />
          {/* <Connectbutton /> */}
        </div>
      </div>

      <div className="hamburgermenu">
        <label>
          <input type="checkbox" />
          <span className="menu">
            {' '}
            <span className="hamburger"></span>{' '}
          </span>
          <ul>
            <li>
              {' '}
              <a href="/nft ">Chiliz NFTs </a>
            </li>

            <li>
              {' '}
              <a href="https://www.fanmarketcap.com/" target="_blank">
                Fan Tokens{' '}
              </a>
            </li>

            <li>
              <a href="https://bridge.chiliz.com/transfer" target="_blank">
                Bridge{' '}
              </a>
            </li>
          </ul>
        </label>
      </div>
    </header>
  )
}

export default Header
