import { useCallback, useRef, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget, Theme } from '@uniswap/widgets'
import { useActiveProvider } from '../connectors'
import { JSON_RPC_URL } from '../constants'
import styles from '../styles/Home.module.css'
import '@uniswap/widgets/fonts.css'
import Footer from './Footer'

const CHZ = '0x3506424f91fd33084466f402d5d97f05f8e3b4af'

const MY_TOKEN_LIST = [
  {
    name: 'Chiliz',
    address: '0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    symbol: 'CHZ',
    decimals: 18,
    chainId: 1,
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png',
  },
]

const theme: Theme = {
  container: '#f6f6f6',
  primary: '#000',
  secondary: '#666',
  module: '#ffff',
  accent: '#FF1256',
  dialog: '#FFF',
  interactive: '#fff',
  borderRadius: 0.8,
}

export default function App() {
  // When a user clicks "Connect your wallet" in the SwapWidget, this callback focuses the connectors.
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])

  // The provider to pass to the SwapWidget.
  // This is a Web3Provider (from @ethersproject) supplied by @web3-react; see ./connectors.ts.
  const provider = useActiveProvider()

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.demo}>
          <div className="Uniswap">
            <SwapWidget
              jsonRpcEndpoint={JSON_RPC_URL}
              tokenList={MY_TOKEN_LIST}
              provider={provider}
              onConnectWallet={focusConnectors}
              defaultInputTokenAddress="NATIVE"
              defaultInputAmount=""
              defaultOutputTokenAddress={CHZ}
              theme={theme}
              width="100%"
            />
          </div>
        </div>
        <Footer></Footer>
      </main>
    </div>
  )
}
