import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import Container from './components/Container'
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
  <React.StrictMode>
          <BrowserRouter>

        <Container/>

    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
)
