import React from 'react'
import '@uniswap/widgets/fonts.css'
import '../styles/new.css'
import XLogo from '../logo/x_logo.png'
import Email from '../logo/email_icon.png'

function Footer() {
  return (
    <div className="footer">
      <a target="_blank" href="https://twitter.com/chilizswap">
        <img src={XLogo} alt="logo" className="logo" />
      </a>

      <a href="mailto:info@chilizswap.com">
        <img src={Email} alt="logo" className="logo" />
      </a>

      <p className="ref">Made with 🌶️ by chilizens for chilizens</p>
    </div>
  )
}

export default Footer
